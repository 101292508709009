import React, { useState } from 'react';
import { Snackbar, Alert, Button } from '@mui/material';

export const TrackingNotice = () => {
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Snackbar
      open={open}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      style={{width: '100%'}}
    >
      <Alert
        onClose={handleClose}
        severity="info"
        sx={{ width: '100%' }}
        action={
          <Button color="inherit" size="small" onClick={handleClose}>
            OK
          </Button>
        }
      >
        {`We use cookies and track basic information to enhance your experience and understand how our website is used. 
        Your data is handled in compliance with GDPR. By continuing to use this site, you agree to this.`}
      </Alert>
    </Snackbar>
  );
};